
.vehicle-enter {
    opacity: 0;
}
.vehicle-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.vehicle-exit {
    opacity: 1;
}
.vehicle-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}
  