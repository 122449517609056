*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --theme-primary: #232f3e;
  --theme-lighter-alt: #dce1e8;
  --theme-lighter: #bdc7d2;
  --theme-light: #a1aebd;
  --theme-tertiary: #8796a8;
  --theme-secondary: #6e7f92;
  --theme-dark-alt: #58697d;
  --theme-dark: #445468;
  --theme-darker: #324153;
  --neutral-lighter-alt: #f8f8f8;
  --neutral-lighter: #f4f4f4;
  --neutral-light: #eaeaea;
  --neutral-quaternary-alt: #dadada;
  --neutral-quaternary: #d0d0d0;
  --neutral-tertiary-alt: #c8c8c8;
  --neutral-tertiary: #a19f9d;
  --neutral-secondary: #605e5c;
  --neutral-primary-alt: #3b3a39;
  --neutral-primary: #323130;
  --neutral-dark: #201f1e;
  --black: #000000;
  --white: #ffffff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New,
    monospace;
}

.drop-down-menu,
.ck-rounded-corners .ck.ck-balloon-panel {
  z-index: 1000001 !important;
}

.ck.ck-content ul,
.ck.ck-content ul li{
  list-style-type: inherit;
}

blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: 5px solid #ccc;
}

.ck.ck-content ul {
  padding-left: 20px;
}

.ck.ck-content ol,
.ck.ck-content ol li{
  list-style-type: decimal;
}

.ck.ck-content ol {
  padding-left: 20px;
}

div.filled {
  color: #605e5cb3;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.datePickerStyles .ms-TextField-fieldGroup {
  border: 1px solid #dadada;
  background: transparent;
}

div.ms-FocusZone {
  align-items: center;
}

div.empty .ms-TextField-field {
  font-size: 12px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.ms-DetailsRow-cell {
  text-align: center;
  margin: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bordless>div>div {
  border: 1px solid #dadada;
  outline: none;
  background: transparent;
}

div.ms-DetailsHeader-cell  {
  height: 100%;
}

div.ms-DetailsHeader-cell>span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

div.ms-DetailsHeader {
  height: 200px;
}

div.ms-DetailsHeader {
  height: 60px;
  padding: 0;
}

span.ms-DetailsHeader-cellName {
  word-break: break-word;
  white-space: pre-line;
  line-height: 14px;
  font-size: 12px;
}

div.datePickerStyles {
  & div.ms-TextField-fieldGroup {
    align-items: center;
  }
}

.Toastify {
  .Toastify__toast-container {
    &.Toastify__toast-container--top-right {
      .Toastify__toast {
        padding: 10px 10px 10px 20px;

        &.Toastify__toast--success {
          background-color: rgba(38, 166, 91, 1);
          height: 80px;
        }
        &.Toastify__toast--error {
          background-color: rgba(217, 30, 24, 1);
          height: 80px;
        }
      }
    }
  }
}